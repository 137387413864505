/* eslint-disable sort-imports */
import "./event-detail-page.scss";

import * as R from "ramda";
import { object, shape, string } from "prop-types";
import { graphql } from "gatsby";

import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import Container100 from "../../components/Container/Container100.jsx";
import ContainerBleed from "../../components/Container/ContainerBleed.jsx";
import extractImage from "../../common/extractImage";
import extractPromo3Up from "../../common/extractPromo3Up.js";
import extractPromo2Up from "../../common/extractPromo2Up.js";
import extractImageBlock from "../../common/extractInfoBlock.js";
import extractRichText from "../../common/extractRichText";
import Page from "../../components/Page/Page.jsx";
import Promo2Up from "../../components/Promo2Up/Promo2Up";
import EventHero from "../../components/EventHero/EventHero";
import SignUp from "../../components/SignUp/SignUp.jsx";
import SocialShare from "../../components/SocialShare/SocialShare.jsx";
import RichTextBlock from "../../components/RichTextBlock/RichTextBlock";
const propTypes = {
  pageContext: shape({ id: string.isRequired }).isRequired,
  data: object,
};

const EventDetailPage = (props) => {
  const { } = props;

  const data = R.compose(
    R.evolve({
      eventImage: extractImage,
      eventPromoSection: extractPromo2Up,
      eventContent: extractRichText,
    }),
    R.defaultTo({}),
    R.prop("contentfulEvent"),
  )(props.data);

  const {
    id,
    title,
    slug,
    eventSubtitle,
    eventContent,
    eventPromoSection,
    eventVenue,
    eventImage,
    tag,
    eventDate,
  } = data;
 
  return (
    <Page
      pageTitle={title}
      pageDescription={eventSubtitle}
      className="event-detail-page"
      theme="dark"
    >
      <BreadCrumb pageId={id} override={[{title:"Home",path:"/"},{title:"Events",path:"/events"}, {title:title, path:`/event/${slug}`}]} />
      {title && (
        <EventHero
          title={title}
          subtitle={eventSubtitle}
          venue={eventVenue}
          foregroundImage={eventImage}
          tag={tag}
          eventDate={eventDate}
        />
      )}

      {eventContent && (
        <Container100 className="event-detail__content">
          <SocialShare />
          <RichTextBlock className="event-detail__rich-text" richText={eventContent} />
        </Container100>
      )}

      {eventPromoSection && <Promo2Up {...eventPromoSection} />}

      <ContainerBleed className="detail-page__sign-up-container">
        <SignUp
          title="Join Our Movement"
          subtitle="Sign up for our emails and learn how to get involved in Vision for Justice and help transform our criminal-legal system."
        />
      </ContainerBleed>
    </Page>
  );
};

EventDetailPage.propTypes = propTypes;
export default EventDetailPage;
export const query = graphql`
  query EventPageQuery($id: String!) {
    contentfulEvent(id: { eq: $id }) {
      id
      eventDate(formatString: "MM/DD/YYYY")
      slug
      title
      eventSubtitle
      tag {
        id
        title
        color {
          id
          colorValue
        }
      }
      eventVenue {
        venueState
        venueCity
        venueName
      }
      eventImage {
        gatsbyImageData
      }
      eventPromoSection {
        promo1 {
          ...ImageBlock
        }
        promo2 {
          ...ImageBlock
        }
      }
      eventContent {
        raw
      }
    }
  }
`;
