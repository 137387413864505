import "./promo.scss";

import * as R from "ramda";

import { node, object, shape, string } from "prop-types";

import ContentfulLink from "../ContentfulLink/ContentfulLink.jsx";
import { navigate } from "gatsby";
import { tagFields } from "../../common/extractTag.js";
import { useContentfulSiteMap } from "../../common/contentfulSiteMap.jsx";

const propTypes = {
  title: string.isRequired,
  image: node.isRequired,
  text: node.isRequired,
  link: object.isRequired, //conteful link validates
  tag: shape(tagFields),
  hasMargins: Boolean,
};

const Promo = (props) => {
  const { link, tag, image, text, title, hasMargins = false } = props;

  const siteMap = useContentfulSiteMap();

  const handleClick = (link) => {
    const path = link.to || R.path([link.pageId, "path"])(siteMap);
    if (!R.isNil(path)) {
      // for external and media links, open a in a new window
      if (link.type === "external") {
        window.open(path, "_blank");
      }
      // for internal/page links, navigate regularly
      else {
        navigate(path);
      }
    }
  };

  return (
    <div
      data-aos="fade-down"
      className={"promo__card is-clickable " + (hasMargins ? "take-actions" : "")}
    >
      <div
        role="presentation"
        className="promo__content"
        tabIndex="-1"
        onClick={() => handleClick(link)}
      >
        {image && (
          <div
            className="promo__img-wrapper"
            style={{ "--tag-color": tag?.color.colorValue }}
          >
            {tag && <div className="promo__tag">{tag.title}</div>}
            {image}
          </div>
        )}
        {link && text ?<div className="promo__card-content">
          <div className="promo__card-title">{title}</div>
          {text && <div className="promo__card-description">{text}</div>}
          {link && <ContentfulLink className="promo__cta" {...link} />}
        </div>: null}
      </div>
    </div>
  );
};

Promo.propTypes = propTypes;
export default Promo;
