import "./promo-2-up.scss";

import { arrayOf, node, object, shape, string } from "prop-types";
import { useEffect, useRef, useState } from "react";

import Container100 from "../Container/Container100.jsx";
import PlusSigns from "../PlusSigns/PlusSigns.jsx";
import Promo from "../Promo/Promo.jsx";
import cx from "classnames";
import { tagFields } from "../../common/extractTag.js";
import useAOS from "../../common/useAOS";
import useScrollama from "../../common/useScrollama.js";

const propTypes = {
  title: string,
  subtitle: node,
  cards: arrayOf(
    shape({
      title: string.isRequired,
      image: node.isRequired,
      text: node.isRequired,
      link: object.isRequired, //conteful link validates
      tag: shape(tagFields),
    }),
  ).isRequired,
};

const Promo2Up = (props) => {
  const { title, subtitle, cards } = props;

  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);

  const promo2UpRef = useRef();

  useAOS();

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useScrollama(promo2UpRef, {
    offset: 0.5,
    // debug: true,
    onStepEnter: (response) => setShouldAnimate(true),
    once: true,
  });

  return hasMounted ? (
    <section
      id="missing-something"
      ref={promo2UpRef}
      className={cx("promo-2-up", { "promo-2-up--animated": shouldAnimate })}
    >
      <Container100>
        <PlusSigns isRight={true} />
        {title && (
          <h2 data-aos="fade-down" className="promo-2-up__title">
            <strong>{title}</strong>
          </h2>
        )}
        {subtitle && <div className="promo-2-up__description">{subtitle}</div>}
        <div className="promo-2-up__cards">
          {cards.map((card, i) => (
            <Promo key={i} {...card} />
          ))}
        </div>
      </Container100>
      <PlusSigns isLeft={true} moveUp={true} />
    </section>
  ) : null;
};

Promo2Up.propTypes = propTypes;
export default Promo2Up;
