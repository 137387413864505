import * as R from "ramda";

import extractImageBlock from "./extractImageBlock.jsx";
import extractRichText from "./extractRichText.jsx";

export default R.compose(
  R.applySpec({
    title: R.prop("promo2UpTitle"),
    subtitle: R.compose(
      R.unless(R.isNil, extractRichText),
      R.prop("promo2UpSubtitle"),
    ),
    cards: (data) => {
      return ["promo1", "promo2"].map((id) =>
        R.compose(extractImageBlock, R.prop(id))(data),
      );
    },
  }),
  R.defaultTo({}),
);
