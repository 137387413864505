import "./rich-text-block.scss";

import { node, string } from "prop-types";

const propTypes = {
  secondaryHeadline: string,
  richText: node.isRequired,
};

const RichTextBlock = (props) => {
  const { secondaryHeadline, richText } = props;
  return (
    <div className="rte-block">
      {secondaryHeadline && (
        <h2 className="rte-block__headline">
          <strong>{secondaryHeadline}</strong>
        </h2>
      )}
      <div className="rte-block__content">{richText}</div>
    </div>
  );
};

RichTextBlock.propTypes = propTypes;

export default RichTextBlock;
