import * as R from "ramda";

import extractLink, { linkFields } from "./extractLink.js";
import extractTag, { tagFields } from "./extractTag.js";
import { node, shape, string } from "prop-types";

import extractImage from "./extractImage.jsx";
import { renderRichText } from "gatsby-source-contentful/rich-text";

export default R.compose(
  R.applySpec({
    title: R.prop("title"),
    text: R.compose(R.unless(R.isNil, renderRichText), R.prop("text")),
    image: R.compose(extractImage, R.prop("image")),
    link: R.compose(R.unless(R.isNil, extractLink), R.prop("link")),
    tag: R.compose(R.unless(R.isNil, extractTag), R.prop("tag")),
  }),
  R.defaultTo({}),
);

export const imageBlockFields = {
  title: string,
  text: string,
  image: node,
  link: shape(linkFields),
  tag: shape(tagFields),
};
