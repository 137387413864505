import * as R from "ramda";

import extractLink, { linkFields } from "./extractLink.js";
import extractTag, { tagFields } from "./extractTag.js";
import { node, shape, string } from "prop-types";

import extractImage from "./extractImage.jsx";
import { renderRichText } from "gatsby-source-contentful/rich-text";

export default R.compose(
  R.applySpec({
    title: R.prop("title"),
    sectionTitle: R.prop("sectionTitle"),
    text: R.compose(R.unless(R.isNil, renderRichText), R.prop("description")),
    image: R.ifElse(
      (data) => data === null,
      //return undefined so components render nothing
      () => undefined,
      R.compose(extractImage, R.prop("image")),
    ),
    link: R.ifElse(
      (data) => data === null,
      //return undefined so components render nothing
      () => undefined,
      R.compose(extractLink, R.prop("link")),
    ),
    tag: R.compose(R.unless(R.isNil, extractTag), R.prop("tag")),
  }),
  R.defaultTo({}),
);

export const infoBlockFields = {
  title: string,
  sectionTitle: string,
  text: string,
  image: node,
  link: shape(linkFields),
  tag: shape(tagFields),
};
