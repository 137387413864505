import "./social-share.scss";

import { useEffect, useState } from "react";

import { string } from "prop-types";

const propTypes = {
  className: string,
};

const SocialAccounts = (props) => {
  const [accounts, setAccounts] = useState(null);

  useEffect(() => {
    //do this in use effect because it references the document
    const pageTitleEl = document.querySelector("meta[name='title']");
    const pageDescriptionEl = document.querySelector(
      "meta[name='description']",
    );

    // og might be undefined, so only get the content if it is defined
    const getContent = (metaInfo) =>
      metaInfo ? metaInfo.getAttribute("content") : "";

    const title = encodeURIComponent(getContent(pageTitleEl));
    const description = encodeURIComponent(getContent(pageDescriptionEl));
    const pageUrl = encodeURIComponent(window.location.href);
    const accounts = [
      {
        href: `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`,
        icon: facebookIcon,
      },
      {
        href: `https://twitter.com/intent/tweet?url=${pageUrl}`,
        icon: twitterIcon,
      },
      {
        href: `https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}`,
        icon: linkedinIcon,
      },
    ];
    setAccounts(accounts);
  }, []);

  return (
    <div className="social-share">
      <div className="social-share__label">Share</div>
      {accounts &&
        accounts.map((account, i) => {
          const { icon, href } = account;
          return (
            <a
              key={i}
              className="social-share__link icon"
              href={href}
              target="_blank"
              rel="noreferrer"
            >
              {icon}
            </a>
          );
        })}
    </div>
  );
};

SocialAccounts.propTypes = propTypes;
export default SocialAccounts;

const facebookIcon = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0v24h24V0H0zm16 7h-1.923C13.461 7 13 7.252 13 7.889V9h3l-.239 3H13v8h-3v-8H8V9h2V7.077C10 5.055 11.064 4 13.461 4H16v3z"
      fill="currentColor"
    />
  </svg>
);

const twitterIcon = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M.861 0v24h24V0h-24zm18.862 9.237c.208 4.617-3.235 9.765-9.33 9.765a9.286 9.286 0 01-5.032-1.475 6.605 6.605 0 004.86-1.359 3.285 3.285 0 01-3.066-2.28 3.3 3.3 0 001.482-.056c-1.579-.317-2.668-1.739-2.633-3.26.442.246.95.394 1.486.411A3.289 3.289 0 016.474 6.6a9.32 9.32 0 006.766 3.43 3.285 3.285 0 015.594-2.993 6.568 6.568 0 002.085-.796 3.292 3.292 0 01-1.443 1.816 6.578 6.578 0 001.885-.517 6.707 6.707 0 01-1.638 1.697z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

const linkedinIcon = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0,0 L0,32 L32,32 L32,0 L0,0 Z M10.6666667,25.3333333 L6.66666667,25.3333333 L6.66666667,10.6666667 L10.6666667,10.6666667 L10.6666667,25.3333333 Z M8.66666667,8.976 C7.37866667,8.976 6.33333333,7.92266667 6.33333333,6.624 C6.33333333,5.32533333 7.37866667,4.272 8.66666667,4.272 C9.95466667,4.272 11,5.32533333 11,6.624 C11,7.92266667 9.956,8.976 8.66666667,8.976 Z M26.6666667,25.3333333 L22.6666667,25.3333333 L22.6666667,17.8613333 C22.6666667,13.3706667 17.3333333,13.7106667 17.3333333,17.8613333 L17.3333333,25.3333333 L13.3333333,25.3333333 L13.3333333,10.6666667 L17.3333333,10.6666667 L17.3333333,13.02 C19.196,9.572 26.6666667,9.31733333 26.6666667,16.3213333 L26.6666667,25.3333333 Z"
      fill="currentColor"
      fillRule="nonzero"
    ></path>
  </svg>
);
