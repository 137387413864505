import * as R from "ramda";

import extractImageBlock from "./extractImageBlock.jsx";
import { renderRichText } from "gatsby-source-contentful/rich-text";

export default R.compose(
  R.applySpec({
    title: R.prop("promo3UpTitle"),
    subtitle: R.compose(
      R.unless(R.isNil, renderRichText),
      R.prop("promo3UpSubtitle"),
    ),
    cards: (data) => {
      return ["promo1", "promo2", "promo3"].map((id) =>
        R.compose(extractImageBlock, R.prop(id))(data),
      );
    },
  }),
  R.defaultTo({}),
);
