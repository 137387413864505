import "./detail-page-hero.scss";

import * as R from "ramda";

import { node, object } from "prop-types";

import MediaBlock from "../MediaBlock/MediaBlock.jsx";

const propTypes = { image: node, tag: object };

const DetailPageHero = (props) => {
  const { image, tag } = props;

  return (
    <div
      style={{ "--tag-color": R.path(["color", "colorValue"], tag) }}
      className="detail-page-hero"
    >
      <MediaBlock offset={0.9}>
        <div className="detail-page-hero__foreground-img">{image}</div>
      </MediaBlock>
    </div>
  );
};

DetailPageHero.propTypes = propTypes;

export default DetailPageHero;
