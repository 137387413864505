import "./event-hero.scss";

import { node, object, shape, string } from "prop-types";

import MediaBlock from "../MediaBlock/MediaBlock.jsx";
import YouTube from "react-youtube";
import cx from "classnames";
import styled from "styled-components";
import tagFields from "../../common/extractTag.js";
import useAOS from "../../common/useAOS.js";
import DetailPageHero from "../DetailPageHero/DetailPageHero";
import * as R from "ramda";

const propTypes = {
  title: string.isRequired,
  subtitle: node,
  foregroundImage: node.isRequired,
  foregroundVideoId: string,
  backgroundImage: node,
  tag: shape(tagFields),
  eventDate: string,
  venue: object,
};

//handle the background scrim color propvided by contenful tag
const SubPageHeroImgWrapper = styled.div`
  &:before {
    background: ${(props) =>
      props.color ? props.color.colorValue : "#000000"};
  }
`;

const SubPageHero = (props) => {
  const {
    foregroundImage,
    foregroundVideoId,
    backgroundImage,
    title,
    subtitle,
    tag,
    eventDate,
    venue,
  } = props;

  useAOS();
  const eventDatateObj = new Date(eventDate);
  const now = new Date();

  return (
    <section className="event-hero">
      <div className="container container--100">
        <p className="event-hero__eyebrow">
          {eventDatateObj < now ? "Past Event" : "Upcoming Event"}
        </p>
        <h1
          data-aos="fade-down"
          data-aos-offset="0"
          className="event-hero__title"
        >
          <strong>{title}</strong>
        </h1>
        {tag&& <div
          className="event-hero__tag"
          style={{ background: tag?.color?.colorValue }}
        >
          {tag?.title}
        </div>}

        {subtitle && <div
          data-aos="fade-down"
          data-aos-offset="0"
          className="event-hero__subtitle"
        >
          {subtitle}
        </div>}
          <div
          data-aos="fade-down"
          data-aos-offset="0"
          className="event-hero__meta"
        >
          {`${venue?.venueName? venue?.venueName:' '} ${venue?.venueCity? venue?.venueCity:'  '} ${venue?.venueState? venue?.venueState:' '}`}
         { venue? <span className="bull"></span>: null} {eventDate}
        </div>

        {foregroundImage && (
          <div
            style={{ "--tag-color": R.path(["color", "colorValue"], tag) }}
            className="event-hero__img-container"
          >
            <MediaBlock offset={0.9}>
              <div className="event-hero__foreground-img">
                {foregroundImage}
              </div>
            </MediaBlock>
          </div>
        )}
      </div>
    </section>
  );
};

SubPageHero.propTypes = propTypes;
export default SubPageHero;
